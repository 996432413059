<template>
  <div class="px-7 py-4" style="background: #f7f7f7; height: auto">
    <v-container>
      <!-- Title for the resources section -->
      <div style="display: flex; justify-content: center; margin-top: 20px">
        <!-- Adjust margin as needed -->
        <span style="font-size: 24px; font-weight: 700"
          >Tips, Tricks and Tools</span
        >
      </div>
      <hr style="border: 1px solid gray" />
      <v-row>
        <v-col cols="12" md="3" class="pl-3">
          <v-autocomplete
            label="Category"
            outlined
            dense
            v-model="category"
            :items="categoryList"
            item-value="id"
            item-text="category_name"
            color="#7253CF"
            ref="combobox"
            class="custom-autocomplete"
            style="color: rgb(114, 83, 207); border-radius: 10px"
            @change="getCommonResource"
            ><template v-slot:label>
              <div style="display: flex; align-items: center">
                <img
                  :src="require('@/assets/thingsToDo/categoryvector.png')"
                  alt="category image"
                  style="width: 20px; height: 20px; margin-right: 8px"
                />
                <span style="color: rgb(114, 83, 207)"> Select Category</span>
              </div>
            </template>
          </v-autocomplete>
        </v-col>
      </v-row>
      <v-data-table
        hide-default-footer
        fixed-header
        :items="campaignDataTable111.items"
        :loading="campaignDataTable111.loading"
        :items-per-page="
          campaignDataTable111[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        :server-items-length="
          campaignDataTable111[$API_KEYS.PAGE_INFO][$API_KEYS.ITEMS_PER_PAGE]
        "
        loading-text="Loading... Please wait"
        class="elevation-1"
        mobile-breakpoint
      >
        <template v-slot:body>
          <v-expansion-panels multiple v-model="singlePanel" accordion>
            <!-- Show 'No data available' message if noData is true -->
            <div v-if="noData" class="text-center pa-4">No data available</div>

            <!-- Iterate over categories, creating a panel for each -->
            <v-expansion-panel
              v-for="(category, index) in groupedCategories"
              :key="index"
            >
              <!-- Header for each expansion panel -->
              <v-expansion-panel-header>
                <v-row
                  @click="handleSubcategoryClick(category.subcategory, index)"
                >
                  <v-col class="d-flex align-center">
                    <!-- Align items vertically in the center -->
                    <div
                      class="chapter-number"
                      :style="{ backgroundColor: getRandomColor() }"
                    >
                      {{ index + 1 }}
                    </div>
                    &nbsp;&nbsp;
                    <span class="subcategoryName"
                      >Chapter {{ index + 1 }}:
                      {{ category.subcategory_name }}</span
                    >

                    <!-- Play Icon -->

                    <v-btn
                      v-if="category.video_url && $vuetify.breakpoint.smAndUp"
                      class="actionColumn-btn"
                      style="margin-left: 4px"
                      color="#F2F2F2"
                      @click="openVideo(category.video_url)"
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="icons"
                            color="#6B6B6B"
                            size="25"
                          >
                            mdi-video
                          </v-icon>
                        </template>
                        <span>Video </span>
                      </v-tooltip>
                    </v-btn>
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="15"
                      style="margin-left: 13%"
                      v-if="category.video_url && $vuetify.breakpoint.xsOnly"
                      @click="openVideo(category.video_url)"
                    >
                      mdi-video
                    </v-icon>

                    <v-btn
                      v-if="category.video_url && $vuetify.breakpoint.smAndUp"
                      @click="getSubcategoryVideoDownload(category.subcategory)"
                      class="actionColumn-btn"
                      style="margin-left: 10px"
                      color="#F2F2F2"
                      :loading="
                        btnLoading && indexClicked === category.subcategory
                      "
                    >
                      <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                          <v-icon
                            dark
                            v-bind="attrs"
                            v-on="on"
                            class="icons"
                            color="#6B6B6B"
                            size="25"
                          >
                            mdi-download
                          </v-icon>
                        </template>
                        <span>Download </span>
                      </v-tooltip>
                    </v-btn>
                    <!-- Share Icon -->
                   <v-btn
                      v-if="category.video_url && $vuetify.breakpoint.xsOnly"
                      @click="getSubcategoryVideoDownload(category.subcategory)"
                      class="actionColumn-btn"
                      style="margin-left: 1px"
                      color="#F2F2F2"
                      :loading="
                        btnLoading && indexClicked === category.subcategory
                      "
                    >
                    <v-icon
                      dark
                      v-bind="attrs"
                      v-on="on"
                      class="icons"
                      color="#6B6B6B"
                      size="15"
                    >
                      mdi-download
                    </v-icon>
                    </v-btn>
                  </v-col>
                </v-row>
              </v-expansion-panel-header>

              <!-- Content within each expansion panel -->
              <v-expansion-panel-content>
                <v-data-table
                  hide-default-footer
                  fixed-header
                  :headers="campaignDataTable1.headers"
                  :items="campaignDataTable1.items"
                  :loading="campaignDataTable1.loading"
                  :items-per-page="
                    campaignDataTable1[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  :server-items-length="
                    campaignDataTable1[$API_KEYS.PAGE_INFO][
                      $API_KEYS.ITEMS_PER_PAGE
                    ]
                  "
                  loading-text="Loading... Please wait"
                  class="elevation-1"
                  mobile-breakpoint
                >
                  <!-- Custom row for each item -->
                  <template v-slot:item="row">
                    <tr>
                      <!-- File title -->
                      <td
                        class="text-center row-item"
                        style="align-items: center"
                      >
                        <!--  <img
                          src="@/assets/thingsToDo/filetype.png"
                          height="20"
                          alt="File Type Icon"
                          style="
                            margin-right: 8px;
                            width: 22px;
                            height: 16px;
                            opacity: 1;
                          "
                        />-->
                        {{ row.item.title }}
                      </td>

                      <!-- Notes column -->
                      <td class="text-center row-item">
                        {{ row.item.notes || "No notes provided" }}
                      </td>
                      <td
                        class="text-center row-item"
                        v-if="row.item.video_url"
                      >
                        <a :href="row.item.video_url" target="_blank"> URL</a>
                      </td>
                      <!-- Action button for download -->
                      <td class="text-center row-item" v-else>
                        <v-btn
                          @click="getReport(row.item.id)"
                          class="actionColumn-btn"
                          color="#F2F2F2"
                          :loading="btnLoading && indexClicked === row.item.id"
                        >
                          <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                              <v-icon
                                dark
                                v-bind="attrs"
                                v-on="on"
                                class="icons"
                                color="#6B6B6B"
                                size="25"
                              >
                                mdi-download
                              </v-icon>
                            </template>
                            <span>Download</span>
                          </v-tooltip>
                        </v-btn>
                      </td>
                    </tr>
                  </template>
                </v-data-table>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </template>
      </v-data-table>

      <v-card flat class="footer-card">
        <v-card-text>
          <v-row no-gutters>
            <v-col
              class="mb-4"
              align-self="center"
              cols="12"
              xs="12"
              sm="6"
              md="4"
            >
              <div
                class="justify-center justify-md-start d-flex px-5 tableHeader-text"
              >
                <div class="alignSelf-center mr-3">Show</div>
                <div class="alignSelf-center mr-5 pb-2" style="width: 60px">
                  <v-select
                    class=""
                    :items="itemsPerPage"
                    hide-details
                    v-model="
                      campaignDataTable111[$API_KEYS.PAGE_INFO][
                        $API_KEYS.ITEMS_PER_PAGE
                      ]
                    "
                    dense
                  ></v-select>
                </div>

                <div class="alignSelf-center">
                  {{
                    campaignDataTable111[$API_KEYS.PAGE_INFO][
                      $API_KEYS.PAGE_NUMBER
                    ]
                  }}
                  of
                  {{
                    campaignDataTable111[$API_KEYS.PAGE_INFO][
                      $API_KEYS.TOTAL_PAGE
                    ]
                  }}
                </div>
              </div>
            </v-col>
            <v-spacer></v-spacer>
            <v-col cols="12" xs="12" sm="4" md="4">
              <div>
                <v-pagination
                  v-model="
                    campaignDataTable111[$API_KEYS.PAGE_INFO][
                      $API_KEYS.PAGE_NUMBER
                    ]
                  "
                  :length="
                    campaignDataTable111[$API_KEYS.PAGE_INFO][
                      $API_KEYS.TOTAL_PAGE
                    ]
                  "
                  :total-visible="5"
                  color="#D30024"
                  class="pagination"
                >
                </v-pagination>
              </div>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-container>
    <hr style="border: 1px solid gray" />

    <v-row>
      <v-col cols="12" :class="$vuetify.breakpoint.smAndUp ? 'py-10' : ''">
        <!-- <video-player :src="src"></video-player> -->
        <video
          @click="openVideo"
          style="cursor: pointer"
          :width="$vuetify.breakpoint.smAndUp ? '90%' : '100%'"
          height="100%"
          class="IntroVideo"
          poster="@/assets/publicDonationPage/timelineEvent/introd1.png"
          hidden
        ></video>
      </v-col>
    </v-row>
    <full-screen-video-viewer
      :imageViewer="imageViewer"
      v-if="imageViewer.flag"
    ></full-screen-video-viewer>
  </div>
</template>
<script>
import {
  API_DOWNLOAD_GAME_RESOURCE,
  API_GAME_RESOURCE_COMMON_SUBCATEGORY_GET,
  GET_RESOURCES_CATEGORYLIST,
  API_GAME_RESOURCE_COMMON_DOCUMENT_GET,
  API_GAME_RESOURCE_DOWNLOAD_SUBCATEGORY_VIDEO,
} from "@/constants/APIUrls";
import { ITEMS, LOADING } from "@/constants/ModuleKeys";
import { mapActions, mapGetters } from "vuex";
import _ from "lodash";
import { ROUTER_URL } from "@/constants/urls";
import {
  PAGE_INFO,
  DATA,
  ITEMS_PER_PAGE,
  PAGE_NUMBER,
  TOTAL_PAGE,
  SEARCH,
  SORTING_KEY,
} from "@/constants/APIKeys";
import Axios from "@/api/BaseAxios";
export default {
  name: "CampaignManagement",
  components: {
   
    FullScreenVideoViewer: () =>
      import("@/components/VideoPlayer/FullScreenImageViewer"),
  },
  data() {
    return {
      dropdownVisible: true,
      category: 1,
      subAdminAccess: localStorage.getItem("subadminAccess"),
      adminAccess: localStorage.getItem("adminAccess"),
      dataOptions: { sortBy: [], sortDesc: [] },
      categoryList: [],
      singlePanel: null,
      selectedSubcategoryId: null,
      noData: false,
      imageViewer: {
        src: "",
        flag: false,
        type: "video",
      },
      campaignDataTable: {
        headers: [
          {
            sortable: true,
            text: "Title",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },

          {
            width: "10%",
            text: "Notes",
            align: "center",
            sortable: true,
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      itemsPerPage: [10, 15, 20, 30, 50, 100],
      user_id: localStorage.getItem("user_id"),
      btnLoading: false,
      indexClicked: undefined,
      campaignDataTable1: {
        headers: [
          {
            sortable: true,
            text: "File Name",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },

          {
            width: "10%",
            text: "Notes",
            align: "center",
            sortable: true,
            class: ["tableHeader-text", "tableHeader-bg"],
          },
          {
            sortable: false,
            text: "Actions",
            value: "icon",
            class: ["tableHeader-text", "tableHeader-bg"],
            align: "center",
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      campaignDataTable111: {
        headers: [
          {
            sortable: true,
            text: "Sr.No.",
            align: "center",
            class: ["tableHeader-text", "tableHeader-bg"],
          },

          {
            text: "Subcategory",
            align: "center",
            sortable: true,
            class: ["tableHeader-text", "tableHeader-bg"],
          },
        ],
        [ITEMS]: [],
        [LOADING]: true,
        [PAGE_INFO]: {
          [PAGE_NUMBER]: 1,
          [TOTAL_PAGE]: 15,
          [ITEMS_PER_PAGE]: 10,
        },
        [SEARCH]: "",
        [SORTING_KEY]: "",
      },
      // Example categories data
    };
  },
  computed: {
    ...mapGetters({
      showGetter: "schoolManagement/getShow",
      //   selectedCampaign: "campaign/getSelectedCampaign",
    }),
    //   selectedCampaignData() {
    //     return this.selectedCampaign;
    //   },
    groupedCategories() {
      // Check if items array is empty and return an empty array if so
      if (
        !this.campaignDataTable111.items ||
        this.campaignDataTable111.items.length === 0
      ) {
        console.log("No items to group");
        return [];
      }

      const grouped = this.campaignDataTable111.items.map((category) => {
        return {
          subcategory_name: category.subcategory_name, // Ensure this is being correctly mapped
          subcategory: category.subcategory, // Ensure this is being correctly mapped
          video_url: category.video_url,
          resources: this.campaignDataTable111.items.filter(
            (resource) => resource.category === category.subcategory
          ),
        };
      });

      console.log("grouped", grouped); // Log the result of the groupedCategories
      return grouped;
    },
  },
  watch: {
    //Watcher for detecting change in PAGE_NUMBER
    "campaignDataTable.page_info.page_number": function () {
      this.getCampaignManagementData();
    },
    //Watcher for detecting change in ITEMS_PER_PAGE
    "campaignDataTable.page_info.detail_count": function () {
      this.getCampaignManagementData();
    },
  },
  methods: {
    ...mapActions({
      toggleDeleteSchoolResourceModal:
        "campaignManagement/toggleDeleteSchoolResourceModal",
      // campaign Modal
      showToast: "snackBar/showToast",
    }),
    getRandomColor() {
      const r = Math.floor(200 + Math.random() * 55); // Red: 200-255
      const g = Math.floor(200 + Math.random() * 55); // Green: 200-255
      const b = Math.floor(200 + Math.random() * 55); // Blue: 200-255

      return `rgb(${r}, ${g}, ${b})`;
    },
    openVideo(url) {
      console.log("video opened");
      this.imageViewer.src = url;
      this.imageViewer.flag = true;
    },
  
    getReport(id) {
      const self = this;
      self.btnLoading = true;
      this.indexClicked = id;
      console.log(id);
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.btnLoading = false;
      };
      let formData = {};
      formData["id"] = id;
      Axios.request_GET_BLOB(
        API_DOWNLOAD_GAME_RESOURCE,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
  
    route() {
      this.$router.push({
        name: ROUTER_URL.schoolPanel.children.resourceupload.name,
      });
      //this.$root.$refs.adminLayout.route("school");
    },
    getCommonResource() {
      const self = this;
      this.campaignDataTable111.loading = true;
      const successHandler = (res) => {
        if (res.data.subcategory_list.length === 0) {
          this.noData = true;
        } else {
          this.noData = false;
        }
        self.campaignDataTable111.items = res.data.subcategory_list;
        self.campaignDataTable111[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.campaignDataTable111[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.campaignDataTable111[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.campaignDataTable111.loading = false;
        this.$refs.combobox.isMenuActive = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.campaignDataTable111.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[ITEMS_PER_PAGE] =
        this.campaignDataTable111[PAGE_INFO][ITEMS_PER_PAGE];
      dataTableParams[PAGE_NUMBER] =
        this.campaignDataTable111[PAGE_INFO][PAGE_NUMBER];
      dataTableParams.category_id = this.category;
      Axios.request_GET(
        API_GAME_RESOURCE_COMMON_SUBCATEGORY_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    getCommonResourceDocument(subcategoryId) {
      const self = this;
      this.campaignDataTable1.loading = true;

      const successHandler = (res) => {
        const items = res.data.resource_list.flatMap((category) =>
          category.subcategories.flatMap((subcategory) =>
            subcategory.resources.map((resource) => ({
              id: resource.id,
              title: resource.title,
              notes: resource.notes || "No notes provided", // Default text if no notes
              video_url: resource.video_url || null, // Handle cases where video_url is not available
              document_url: resource.document, // The document URL, assuming this is the file
              created: resource.created,
              modified: resource.modified,
            }))
          )
        );

        // Assign the processed data to campaignDataTable1.items
        console.log("itemsitems", items);
        self.campaignDataTable1.items = items;
        console.log("document_list", self.campaignDataTable1.items);
        self.campaignDataTable1[PAGE_INFO][PAGE_NUMBER] =
          res[DATA][PAGE_INFO][PAGE_NUMBER];
        self.campaignDataTable1[PAGE_INFO][TOTAL_PAGE] =
          res[DATA][PAGE_INFO][TOTAL_PAGE];
        self.campaignDataTable1[PAGE_INFO][ITEMS_PER_PAGE] =
          res[DATA][PAGE_INFO][ITEMS_PER_PAGE];
        self.campaignDataTable1.loading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.campaignDataTable1.loading = false;
      };
      let dataTableParams = {};
      dataTableParams[ITEMS_PER_PAGE] =
        this.campaignDataTable1[PAGE_INFO][ITEMS_PER_PAGE];
      dataTableParams[PAGE_NUMBER] =
        this.campaignDataTable1[PAGE_INFO][PAGE_NUMBER];
      dataTableParams.category_id = this.category;
      dataTableParams.subcategory_id = subcategoryId;
      Axios.request_GET(
        API_GAME_RESOURCE_COMMON_DOCUMENT_GET,
        dataTableParams,
        {},
        successHandler,
        failureHandler,
        false
      );
    },
    handleSubcategoryClick(subcategoryId, index) {
      this.singlePanel = index;
      console.log("Selected Subcategory ID:");
      // Store the selected subcategory_id
      this.selectedSubcategoryId = subcategoryId;

      console.log("Selected Subcategory ID:", subcategoryId);

      // Call another function using the subcategory_id
      this.getCommonResourceDocument(subcategoryId);
    },
    routeGame() {
      this.$router.push({
        name: ROUTER_URL.adminPanel.children.gameresourceManagement.name,
      });
    },
    getCategoryList() {
      const self = this;
      const successHandler = (res) => {
        const data = res.data;
        self.categoryList = data.category_list;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.loading = false;
      };
      const finallyHandler = () => {};
      const formJson = {};
      formJson.search = "game";
      return Axios.request_GET(
        GET_RESOURCES_CATEGORYLIST,

        formJson,
        {},
        successHandler,
        failureHandler,
        false,
        finallyHandler
      );
    },
    downloadVideo(url) {
      const link = document.createElement("a");
      link.href = url;
      link.download = url.split("/").pop(); // Set the download filename (optional)
      link.click();
    },
    editResource(item) {
      this.$router.push({
        name: ROUTER_URL.schoolPanel.children.resourceupload.name,
        query: { id: item.id },
      });
    },
    videoURL(row) {
      window.open(row, "_blank");
    },

    getSubcategoryVideoDownload(id) {
      const self = this;
      self.btnLoading = true;
      this.indexClicked = id;
      console.log(id);
      const successHandler = (res) => {
        const url = window.URL.createObjectURL(new Blob([res.data]));
        const link = document.createElement("a");
        link.href = url;
        let fileName = res.headers["content-disposition"]
          .split(";")[1]
          .split("=")[1]
          .split('"')[1];
        link.setAttribute("download", fileName); //or any other extension
        document.body.appendChild(link);
        link.click();
        self.btnLoading = false;
      };
      const failureHandler = (res) => {
        console.log(res);
        self.btnLoading = false;
      };
      let formData = {};
      formData["subcategory_id"] = id;
      Axios.request_GET_BLOB(
        API_GAME_RESOURCE_DOWNLOAD_SUBCATEGORY_VIDEO,
        formData,
        {},
        successHandler,
        failureHandler,
        false,
        true,
        true
      );
    },
    closeDropdown() {
      // Access the dropdown and close it
      this.$refs.combobox.isMenuActive = false;
    },
    handleSelection(item) {
      // Ensure only one item is selected
      if (this.selectedCategory === item.id) {
        this.selectedCategory = null; // Deselect if the same checkbox is clicked
      } else {
        this.selectedCategory = item.id; // Select the new category
      }
    },
  },
  created() {
    this.debounceGetCampaignManagementData = _.debounce(
      this.getCampaignManagementData,
      500
    );
  },
  mounted() {
    this.getCategoryList();
    this.getCommonResource();
    this.getCampaignManagementData();
  },
};
</script>
<style>
.v-list-item.primary--text.v-list-item--active.v-list-item--highlighted {
  color: #673de5 !important;
  caret-color: #673de5 !important;
}
</style>
<style scoped>
/* Change label color */
.v-text-field >>> .v-input__control .v-input__label {
  color: rgb(114, 83, 207) !important;
}

/* Change border color in both default and focused states */
.v-text-field >>> .v-input__control .v-input__outline {
  border-color: rgb(114, 83, 207) !important;
}

.v-text-field >>> .v-input__control .v-input__outline--focused {
  border-color: rgb(114, 83, 207) !important; /* When the field is focused */
}

/* Change text color inside the input field */
.v-text-field >>> .v-input__control input {
  color: rgb(114, 83, 207) !important;
  font-weight: 700;
}
</style>
<style scoped>
.elevation-1 {
  background-color: #f9f9f9;
  border-radius: 8px;
}

.v-expansion-panel-header {
  font-weight: bold;
}

.v-icon {
  margin-right: 10px;
}
.active-class {
  border-right: 4px solid #38227a;
  border-left: 4px solid #38227a;
  border-radius: 4px;
}
.card-datatable.v-card {
  max-height: 80px;
  margin-bottom: -2px;
  display: flex;
}
.text-capitalize.v-btn {
  position: absolute;
  right: 10px;
  letter-spacing: 0px;
}
span.v-chip {
  border-radius: 4px;
  margin-left: 12px;
}
.alignSelf-center {
  align-self: center;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.ONGOING {
  margin-top: 10px;
  background-color: rgba(231, 111, 0, 0.12);
  color: #e76f00;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.UPCOMING {
  margin-top: 10px;
  background-color: rgba(0, 133, 255, 0.12);
  color: #0085ff;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.COMPLETED {
  margin-top: 10px;
  background-color: rgba(6, 118, 5, 0.12);
  color: #067605;
  border-radius: 10px;
  font-weight: 700;
}
span.v-chip.v-chip--no-color.theme--light.v-size--small.DRAFT {
  margin-top: 10px;
  border-radius: 10px;
  font-weight: 700;
}
.footer-card.v-card {
  margin-top: 0px;
  border-top: 1px solid #e6e6e6;
}
.footer-input.v-input {
  width: 60px;
  padding: 0%;
}
.footer_p {
  margin-left: 25px;
  margin-right: 15px;
  margin-top: 8px;
  color: black;
}
.trow {
  height: 50px;
  background-color: #eeeeee;
  width: 100%;
}
.theader {
  font-weight: 400;
  font-size: 13px;
  padding: 12px;
  text-align: center;
}
@media (min-width: 1281px) {
  .v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
    /*min-width: 1500px;*/
  }
}

.v-data-table.elevation-1.v-data-table--fixed-header.theme--light {
  font-family: Lato;
  border-radius: 0px;
}
.table-div {
  overflow-x: scroll;
}

.v-btn {
  font-family: Lato;
  font-weight: 600;
}
.v-input {
  font-family: Lato;
}
.pagination {
  float: right;
}
@media (min-width: 0px) and (max-width: 600px) {
  .pagination {
    float: none;
  }
  .footer-card.v-card {
    max-height: 200px;
    align-items: center;
    text-align: center;
  }
  .footer_p {
    margin-left: 60px;
  }
  .v-input.search-input {
    display: flex;
    width: 240px;
    margin-left: 10px;
  }
  .v-icon.notranslate.mobile-menu {
    position: absolute;
    top: 25px;
  }
  .table-div {
    overflow: scroll;
  }
}
</style>
<style>
.custom-autocomplete .v-list-item--dense .v-list-item__title,
.v-list-item--dense .v-list-item__subtitle,
.v-list--dense .v-list-item .v-list-item__title,
.v-list--dense .v-list-item .v-list-item__subtitle {
  font-size: 1rem;
  font-weight: 500;
  line-height: 2rem;
}

.custom-autocomplete .v-list-item {
  font-size: 20px; /* Set the font size for dropdown items */
  height: 50px; /* Optional: Adjust the height of each dropdown item */
  line-height: 50px; /* Vertically center text */
}

.custom-autocomplete .v-list-item__title {
  font-size: 20px; /* Ensure the text in the title has the desired font size */
}
.custom-autocomplete {
  font-size: 18px; /* Set font size */
  height: 60px; /* Increase height */
}

.custom-autocomplete .v-input__control {
  font-size: 18px; /* Ensure text inside the input matches the font size */
  height: 60px; /* Adjust height of the input control */
  line-height: 60px; /* Align text vertically */
}

.custom-autocomplete .v-label {
  font-size: 18px; /* Optional: Adjust label font size */
}
.tableHeader-text {
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 14px !important;
  line-height: 161.5% !important;
  color: #1d1d1d !important;
  padding: 0px 4px !important;
}
.tableHeader-bg {
  background: #e7e5ed !important;
}
.actionColumn-btn {
  box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.12);
}
/* Extra small devices (phones, 600px and down) */
@media only screen and (max-width: 600px) {
}

/* Small devices (portrait tablets and large phones, 600px and up) */
@media only screen and (min-width: 600px) {
}

/* Medium devices (landscape tablets, 960px and up) */
@media only screen and (min-width: 960px) {
  .tableHeader-text {
    font-size: 13px !important;
  }
}

/* Large devices (laptops/desktops, 1264px and up) */
@media only screen and (min-width: 1264px) {
  .tableHeader-text {
    font-size: 14px !important;
  }
}

/* Extra large devices (large laptops and desktops, 1904px and up) */
@media only screen and (min-width: 1904px) {
  .tableHeader-text {
    font-size: 16px !important;
  }
}
@media (max-width: 1281px) {
  .subcategoryName {
    font-weight: bold;
    font-size: 10px;
  }
  .chapter-number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 30px;
    height: 30px;
    border-radius: 9px; /* Rounded square shape */
    background-color: #f0e7ff; /* Light purple background (you can change this color) */
    color: #5e35b1; /* Text color */
    font-weight: bold;
    border: 1px solid #d1c4e9; /* Border color */
  }
  .v-btn:not(.v-btn--round).v-size--default {
    height: 19px;
    min-width: 28px;
    padding: 0 0px;
}
}
@media (min-width: 1281px) {
  .subcategoryName {
    margin-left: 5px;
    font-weight: bold;
    font-size: 18px;
  }
  .chapter-number {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 48px;
    height: 48px;
    border-radius: 15px; /* Rounded square shape */
    background-color: #f0e7ff; /* Light purple background (you can change this color) */
    color: #5e35b1; /* Text color */
    font-weight: bold;
    border: 1px solid #d1c4e9; /* Border color */
  }
}
</style>
